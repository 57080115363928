$(function () {
    $(window).scroll(function () {
        // add animation to Our Services section
        var $serviceBlock = $('.service-block');
        if (!$serviceBlock.hasClass('slide-up')) {
            var serviceBlockTopOffset = $serviceBlock.offset().top;

            var topOfWindow = $(window).scrollTop();
            if (serviceBlockTopOffset < topOfWindow + 850) {
                $serviceBlock.each(function (serviceBlockIndex, serviceBlockItem) {
                    setTimeout(function () {
                        $(serviceBlockItem).addClass('slide-up');
                    }, (serviceBlockIndex * 200));
                });
            }
        }
    });

    // play randomly animated gifs
    var iconsImages = $('.banner-block .icons-block .service-icon-block .icon-image-block');
    var imageNumber = 0;

    setInterval(function () {
        $(iconsImages).eq(imageNumber).find('.effect').hide();
        $(iconsImages).eq(imageNumber).find('img:not(.effect)').show();

        do {
            newImageNumber = randomInteger(0, iconsImages.length - 1);
        } while (newImageNumber == imageNumber);
        imageNumber = newImageNumber;

        $(iconsImages).eq(imageNumber).find('img:not(.effect)').hide();
        $(iconsImages).eq(imageNumber).find('.effect').show();
    }, 4000);

    /**
     * get random int number in [min-max] interval
     *
     * @param min
     * @param max
     * @returns {*}
     */
    function randomInteger(min, max) {
        var rand = min + Math.random() * (max + 1 - min);
        rand = Math.floor(rand);
        return rand;
    }
});